<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center mt-8">
      <LoadingSpinner />
    </div>

    <!-- Review section -->
    <div v-else-if="!loading">
      <!-- Past Draws -->
      <div v-if="formattedPastDraws" class="mb-9 mx-1">
        <p class="mb-2 font-bold px-2">Past Draws</p>
        <hr class="border-gray-300 mb-2" />
        <section class="py-1 px-2">
          <p class="mb-4">The following cannot be voided as the draw has closed.</p>
          <div class="flex flex-row justify-between items-start">
            <div>
              <p class="font-bold">Draw {{ formattedPastDraws.drawRange }}</p>
              <p class="font-medium text-sm">{{ formattedPastDraws.dateRange }}</p>
            </div>
            <Badge variant="danger" label="Non-Voidable" />
          </div>
        </section>
        <hr class="border-gray-300 mb-2 mt-4" />
      </div>

      <!-- Orders to be Voided -->
      <div class="mx-1">
        <p class="mb-2 font-bold px-2">Orders to be Voided</p>
        <hr class="border-gray-300 mb-2" />
        <div class="py-1 px-2 flex flex-row justify-between items-start" v-if="formattedCurrentDraw">
          <div>
            <p class="font-bold">Draw #{{ formattedCurrentDraw.drawRange }}</p>
            <p class="font-medium text-sm">{{ formattedCurrentDraw.dateRange }}</p>
          </div>
          <Badge variant="success" label="Current" />
        </div>
        <div class="py-1 px-2 flex flex-row justify-between items-start" v-if="formattedFutureDraws">
          <div>
            <p class="font-bold">Draw {{ formattedFutureDraws.drawRange }}</p>
            <p class="font-medium text-sm">{{ formattedFutureDraws.dateRange }}</p>
          </div>
          <Badge variant="secondary" label="Future" />
        </div>
        <hr class="border-gray-300 mt-2" />
      </div>

      <div class="text-right">
        <p class="font-bold mt-4 pr-2">
          Refunded Amount: {{ formatCurrency(totalAmount / 100, { precision: 2, currency: currency }) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import OrderServiceV2 from '@/lib/order-service-v2';
import EventServiceV2 from '@/lib/event-service-v2';
import { format } from 'date-fns';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import Badge from '@/components/rbComponents/Badge.vue';

export default {
  props: {
    subscriptionId: {
      type: String,
      required: true
    }
  },
  components: {
    LoadingSpinner,
    Badge
  },
  computed: {
    formattedPastDraws() {
      if (this.pastDraws?.length > 0) {
        return {
          drawRange: this.pastDraws.length > 0 ? this.formatDrawRanges(this.pastDraws) : null,
          dateRange: this.pastDraws.length > 0 ? this.formatDateRanges(this.pastDraws) : null
        };
      } else {
        return null;
      }
    },
    formattedCurrentDraw() {
      if (this.currentDraw) {
        return {
          drawRange: this.currentDraw.drawNum,
          dateRange: format(new Date(this.currentDraw.eventStartDate), 'LLL d yyyy')
        };
      } else {
        return null;
      }
    },
    formattedFutureDraws() {
      if (this.futureDraws?.length > 0) {
        return {
          drawRange: this.formatDrawRanges(this.futureDraws),
          dateRange: this.formatDateRanges(this.futureDraws)
        };
      } else {
        return null;
      }
    },
    totalAmount() {
      const voidableDraws = [];

      if (this.currentDraw?.amountPaidCents) {
        voidableDraws.push(this.currentDraw);
      }

      if (this.futureDraws?.length > 0) {
        voidableDraws.push(...this.futureDraws);
      }

      if (voidableDraws.length > 0) {
        return voidableDraws.reduce((total, draw) => total + draw?.amountPaidCents, 0);
      } else {
        return 0;
      }
    }
  },
  data() {
    return {
      loading: false,
      pastDraws: null,
      currentDraw: null,
      futureDraws: null,
      currency: null
    };
  },
  async mounted() {
    if (this.subscriptionId) {
      this.loading = true;

      await this.getSubscription();

      this.loading = false;
    }
  },
  methods: {
    async getSubscription() {
      try {
        const params = {
          subscriptionId: this.subscriptionId,
          pageSize: 52
        };

        const response = await OrderServiceV2.listOrders(params);
        const allDraws = response.data;

        const activeEventResponse = await EventServiceV2.listEvents({
          pageSize: 1,
          page: 0,
          goldrush: true,
          status: 'active'
        });

        const activeEvent = activeEventResponse.data[0];

        // The "active" goldrush draw is the one that is currently open or ended
        this.currentDraw = allDraws.find((draw) => draw.eventId === activeEvent.id);

        //past draw are all draws before the current one
        this.pastDraws = allDraws.filter((draw) => draw.drawNum < this.currentDraw.drawNum);

        // future draws are all draws after the current one
        this.futureDraws = allDraws.filter((draw) => draw.drawNum > this.currentDraw.drawNum);

        this.currency = allDraws[0].currency.toUpperCase();
        this.$emit('voidableOrders', { currentDraw: this.currentDraw, futureDraws: this.futureDraws });
      } catch (error) {
        this.loading = false;
        this.errorMessage = this.parseError(error).message;
      }
    },
    formatDrawRanges(draws) {
      const drawRange = {
        start: null,
        end: null
      };

      if (draws.length === 1) {
        return `#${draws[0].drawNum}`;
      }

      draws.sort((a, b) => a.drawNum - b.drawNum);
      drawRange.start = draws[0].drawNum;
      drawRange.end = draws[draws.length - 1].drawNum;

      return `#${drawRange.start} - #${drawRange.end}`;
    },
    formatDateRanges(draws) {
      const dateRange = {
        start: null,
        end: null
      };

      if (draws.length === 1) {
        return format(new Date(draws[0].eventStartDate), 'LLL d yyyy');
      }

      draws.sort((a, b) => a.drawNum - b.drawNum);
      dateRange.start = format(new Date(draws[0].eventStartDate), 'LLL d yyyy');
      dateRange.end = format(new Date(draws[draws.length - 1].eventEndDate), 'LLL d yyyy');

      return `${dateRange.start} - ${dateRange.end}`;
    }
  }
};
</script>
