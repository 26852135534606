<template>
  <div>
    <div class="customer-info customer-name" v-if="customer.name">{{ customer.name }}</div>

    <div class="customer-info customer-address" v-if="customer.addressLine1">{{ customer.addressLine1 }}</div>

    <div class="customer-info customer-city" v-if="customer.city">{{ customer.city }}</div>

    <div class="customer-info customer-state" v-if="customer.state">{{ customer.state }}</div>

    <div class="customer-info customer-postal" v-if="customer.postal">{{ customer.postal }}</div>

    <div class="customer-info customer-phone" v-if="customer.phone">{{ this.formatPhone(customer.phone) }}</div>

    <div class="customer-info customer-email" v-if="customer.email">
      <a :href="`mailto:${customer.email}`">{{ customer.email }}</a>
    </div>

    <div class="customer-info customer-location" v-if="customer.location">{{ customer.location }}</div>
  </div>
</template>
<script>
export default {
  props: ['customer']
};
</script>
<style scoped>
.order-info {
  margin-top: 0.5rem;
}
</style>
