<template>
  <div v-if="this.orders">
    <hr class="border-gray-300" />
    <div v-for="(order, index) in filteredOrders" :key="index">
      <div class="p-2 mt-3">
        <div class="flex flex-row items-start">
          <input
            id="default-checkbox"
            type="checkbox"
            v-model="selectedOrders[order.id]"
            class="w-4 h-4 opacity-0 absolute z-10 cursor-pointer"
            v-if="enableSelect"
          />
          <div
            class="
              mr-4
              mt-1
              w-4
              h-4
              border
              rounded
              border-gray-300
              dark:border-gray-600
              bg-gray-100
              dark:bg-gray-700
              flex
              items-center
              justify-center
            "
            :class="{ 'bg-green-500': selectedOrders[order.id] }"
            v-if="enableSelect"
          >
            <svg class="w-3 h-3 text-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
            </svg>
          </div>

          <div class="w-full">
            <div class="flex flex-row justify-between">
              <p class="font-bold mb-3 inline">
                {{ order.ticketPricing ? 'Order #' : 'Donation #'
                }}{{ isSingleVoid ? formatUuid(order.id) : formatUuid(order.uuid) }}
              </p>
              <p class="mb-0 inline">
                {{ formatCurrency(amountPaid(order), { precision: 2, currency: currency() }) }}
              </p>
            </div>

            <p class="mt-1 text-sm text-gray-500">
              Purchased for
              <a
                :href="`${dashboardUrl}/raffle?id=${isSingleVoid ? order.eventId : order.event.uuid}`"
                class="underline"
              >
                {{ isSingleVoid ? order.eventName : order.event.name }}
              </a>
            </p>
          </div>
        </div>
      </div>
      <hr class="border-gray-300 mt-3" />
    </div>

    <div class="text-right" v-if="orders.length > 1">
      <p class="font-bold mt-4 pr-2">
        Refund Amount:
        {{ refundAmount }}
      </p>
    </div>
  </div>
</template>

<script>
import config from '@/config';

export default {
  props: {
    orders: {
      type: Array
    },
    enableSelect: {
      type: Boolean,
      default: true
    },
    isSingleVoid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOrders: {},
      dashboardUrl: config.RAFFLEBOX_DASHBOARD_URL
    };
  },
  computed: {
    totalAmount() {
      return this.orders.reduce((total, order) => {
        if (this.selectedOrders[order.id]) {
          if (order.ticketPricing) {
            return total + order.ticketPricing.totalPrice;
          } else {
            return total + order.amountCents / 100;
          }
        } else {
          return total;
        }
      }, 0);
    },
    refundAmount() {
      if (this.isSingleVoid) {
        return (this.totalAmount / 100).toFixed(2);
      } else {
        return this.totalAmount.toFixed(2);
      }
    },
    filteredOrders() {
      return this.orders.filter((order) => {
        return !order.voidedAt;
      });
    }
  },
  watch: {
    selectedOrders: {
      handler() {
        const orderIds = Object.keys(this.selectedOrders).filter((orderId) => this.selectedOrders[orderId]);
        this.$emit('onOrderSelected', orderIds);
      },
      deep: true
    }
  },
  methods: {
    amountPaid(order) {
      if (order.ticketPricing) {
        if (this.isSingleVoid) {
          return order.ticketPricing.totalPriceCents / 100;
        } else {
          return order.ticketPricing.totalPrice;
        }
      } else {
        return order.amountCents / 100;
      }
    },
    currency() {
      return this.orders[0].currency?.toUpperCase();
    },
    clearCheckboxes() {
      this.selectedOrders = {};
    }
  }
};
</script>
