<template>
  <div>
    <b-modal
      :id="'email-order-' + modalId"
      title="Email Order"
      v-model="modalShow"
      @ok="handleOk"
      @show="resetForm"
      no-close-on-backdrop
      size="m"
      body-class="position-static"
    >
      <b-form @submit.stop.prevent="onSubmit">
        <b-overlay :show="loading" no-wrap rounded="sm" />
        <b-form-group
          label="Send To"
          label-for="input-email"
          :invalid-feedback="veeErrors.first('input-email')"
          label-class="mb-0"
        >
          <b-form-input
            name="input-email"
            v-model="email"
            v-validate="{ email: true }"
            :state="validateState('input-email')"
            aria-describedby="input-email-feedback"
            data-vv-as="email"
            trim
          />
        </b-form-group>
        <div v-if="errorMessage" class="alert alert-danger">Failed to send order email: {{ errorMessage }}</div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import OrderService from '@/lib/order-service-v2';

export default {
  props: {
    modalId: {
      type: String
    },
    order: {
      type: Object,
      default: () => {
        return {
          id: null
        };
      }
    }
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      id: null,
      loading: false,
      email: null
    };
  },
  methods: {
    resetForm() {
      this.email = this.order.email;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      this.loading = true;
      try {
        await OrderService.sendOrderEmail(this.order.id, this.email);

        this.modalShow = false;
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style></style>
