import Axios from '@/axios';
import config from '@/config';

const ticketsUrl = `/ticket-service/v2/tickets`;
const baseUrl = config.TICKET_API_URL;
const contextRoot = '/download';

export default {
  updateTicket: async (ticketId, data) => {
    const res = await Axios.patch(`/ticket-service/v2/tickets/${ticketId}`, data);

    return res.status == 204;
  },
  unprintEvent: async (eventId) => {
    const res = await Axios.post(`/ticket-service/v2/tickets/unprint`, { eventId: eventId });

    return res.status == 204;
  },
  listTickets: async (params = {}) => {
    const response = await Axios.get(ticketsUrl, {
      params
    });
    return response.data.data;
  },
  getDownloadTicketsUrl: async (params = {}) => {
    const response = await Axios.get(contextRoot, { params: params, baseURL: baseUrl });
    return response.data.data;
  }
};
